import getConfig from 'next/config';
import { parse as parseQueryString } from 'querystring';

const DEFAULT_FALLBACK_URL = 'https://www.johnlewis.com/';
const CONTENT_BASE_PATH = 'content-renderer';

const contentURLMapper = (
  rawUrl: string,
  isHead: boolean = false,
  featureFlags: { [key: string]: string } = {}
): string => {
  const { publicRuntimeConfig } = getConfig();
  const { appEnv } = publicRuntimeConfig;

  const parsedUrl = new URL(rawUrl, DEFAULT_FALLBACK_URL);
  const pathName = parsedUrl.pathname || '';
  const plainQueryString = parsedUrl.search.replace('?', '') || '';
  const parsedQueryString = parseQueryString(plainQueryString);
  const id = pathName && pathName !== '/' ? pathName : '/homepage';

  const pathWithoutTrailingSlash = pathName.endsWith('/')
    ? pathName.slice(0, -1)
    : pathName;

  let featureFlagParam = '';
  if (featureFlags && Object.keys(featureFlags).length !== 0) {
    const featureFlagsString = JSON.stringify(featureFlags);
    const parsedFeatureFlagsString = encodeURIComponent(featureFlagsString);
    featureFlagParam = `&featureFlags=${parsedFeatureFlagsString}`;
  }

  const appEnvParam = appEnv ? `&appEnv=${appEnv}` : '';
  if (isHead) {
    const { section } = parsedQueryString;
    const sectionParams = section ? `section=${section}&` : '';
    return pathWithoutTrailingSlash === ''
      ? `/${CONTENT_BASE_PATH}/homepage.head`
      : `/${CONTENT_BASE_PATH}${pathWithoutTrailingSlash}.head?${sectionParams}includeMetadata=true`;
  }
  return plainQueryString
    ? `/${CONTENT_BASE_PATH}${id}?experimentsEnabled=true&includeCoreJS=false&includePolyfills=false&${plainQueryString}${featureFlagParam}${appEnvParam}`
    : `/${CONTENT_BASE_PATH}${id}?experimentsEnabled=true&includeCoreJS=false&includePolyfills=false${featureFlagParam}${appEnvParam}`;
};

export default contentURLMapper;
